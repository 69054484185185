import React from 'react'

import { SocialIcon } from 'react-social-icons';
import { FaEnvelope } from 'react-icons/fa';

import Layout from '../components/Layout'
import Seo from '../components/Seo'

import { themeStyles } from '../utils/theme'
import { rhythm } from '../utils/typography'
import colors from '../utils/colors'

const styles = {
  outer: {
    width: '100%',
    maxWidth: '600px',
    ...themeStyles.textPadding,
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: rhythm(0.5),
    width: '100%',
    '& *': {
      marginRight: rhythm(0.5),
    },
  },
  button: {
    width: '100%',
    backgroundColor: colors.blue,
    borderRadius: '5%',
    color: colors.white,
    padding: rhythm(0.5),
    fontSize: rhythm(0.75),
    marginTop: rhythm(0.5),
    '& svg': {
      fontWeight: 'normal',
      verticalAlign: 'middle',
      marginRight: rhythm(0.5),
      marginBottom: '3px',
    },
  },
  input: {
    width: '100%',
    padding: rhythm(0.25),
    fontSize: rhythm(0.75),
    marginBottom: rhythm(1.25),
  },
  label: {
    fontWeight: 'bold',
    display: 'block',
    color: colors.black,
    marginBottom: rhythm(0.25),
  }
}

const ContactUs = ({ location }) => (
  <Layout location={location}>
    <Seo title="Contact Us" />
    <div css={styles.outer}>
      <h2>Contact The McGees</h2>
      <div>
        If you're interested in having us housesit for you and your pets, please fill out the form below. You can also contact us in the usual social places.
      </div>
      <div css={styles.social}>
          <SocialIcon url="https://facebook.com/wearemcgees" network="facebook" style={{ height: 32, width: 32 }}/>
        <SocialIcon url="https://twitter.com/direct_messages/create/wearemcgees" network="twitter" style={{ height: 32, width: 32 }}/>
        <SocialIcon url="https://instagram.com/wearemcgees" network="instagram" style={{ height: 32, width: 32 }}/>
      </div>
      <form name="contact" method="POST" action="/thankyou" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <div>
          <label htmlFor="name" css={styles.label}>Your Name</label>
          <input type="text" name="name" defaultValue="" required placeholder="Name" css={styles.input}  css={styles.input} />
        </div>
        <div>
          <label htmlFor="email" css={styles.label}>Your Email</label>
          <input type="email" name="email" defaultValue="" required placeholder="E-Mail Address" css={styles.input} />
        </div>
        <div>
          <label htmlFor="regarding" css={styles.label}>Regarding</label>
          <select name="regarding" css={styles.input} >
              <option value="Housesitting">House And Petsitting</option>
              <option value="Other">Other</option>
          </select>
        </div>
        <div>
          <label htmlFor="dates" css={styles.label}>Dates Requested (Optional)</label>
          <input type="text" name="dates" defaultValue="" placeholder="Desired Dates for Housesitting" css={styles.input} />
        </div>
        <div>
          <label htmlFor="message" css={styles.label}>Message</label>
          <textarea name="message" required placeholder="Enter Your Message" rows="6" css={styles.input} ></textarea>
        </div>
        <div>
          <button type="submit" css={styles.button}><FaEnvelope />Send Message</button>
        </div>
      </form>
    </div>
  </Layout>
)

export default ContactUs